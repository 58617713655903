import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

import { useList } from 'modules/collegialorganization/hooks';

import Title from 'components/Title';
import Spacer from 'components/Spacer';
import Container from 'components/Container';
import Pagination from 'components/Pagination';

import cls from './Collegial.module.scss';

const Collegial = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

   const { items, meta } = useList({
      params: {
         page,
         perPage: 6
      }
   });

  return (
    <section className={cls.section}>
       <Container>
          <Title text={t('collegial_organizations')} />

          <Spacer size={50} />

          <Table
             rowKey='id'
             columns={[
                {
                   title: '№',
                   render: (text, record, index) => (meta.current - 1) * meta.perPage + index + 1
                },
                {
                   key: 'name',
                   title: t('column_collegial_name'),
                   dataIndex: 'name'
                },
                {
                   key: 'description',
                   title: t('column_collegial_description'),
                   dataIndex: 'description'
                },
                {
                   key: 'organing',
                   title: t('column_collegial_organing'),
                   dataIndex: 'organing'
                },
             ]}
             dataSource={items}
             pagination={false}
             scroll={{ x: 1400 }}
          />

          <Spacer size={32} />

          <div className={cls.pagination}>
             <Pagination
                current={meta.current}
                total={meta.totalPages}
                pageRangeDisplayed={5}
                onChange={page => setPage(page)}
             />
          </div>
       </Container>
    </section>
  )
}

export default Collegial;