import 'antd/dist/reset.css';
import 'assets/styles/main.scss';

import config from 'config';

import { http, i18n } from 'services';

i18n.init({
  languages: config.language.list,
  initialLanguage: config.language.initial,
  backend: {
    loadPath: `${config.api.base_url}/v1/gov-function/reference/translations/${config.app.technology}/{{lng}}`
  },
  onChange: () => {}
});

http.init({
  configFn: () => {
    return {
      baseURL: config.api.base_url
    };
  }
});